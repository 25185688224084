import React from 'react';

import { Link } from 'gatsby';

type ButtonLinkProps = {
  className?: string;
  title: string;
  to: string;
  external?: boolean;
};

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  title,
  to,
  external = false,
  className = '',
}) => (
  <>
    {external ? (
      <a
        className={`px-8 py-2 border rounded-full border-spring-leaves-500 text-spring-leaves-500 hover:border-spring-leaves-400 hover:text-spring-leaves-400 ${className}`}
        href={to}
        target="_blank"
        rel="noreferrer noopener"
      >
        {title}
      </a>
    ) : (
      <Link
        className={`px-8 py-2 border rounded-full border-spring-leaves-500 text-spring-leaves-500 hover:border-spring-leaves-400 hover:text-spring-leaves-400 ${className}`}
        to={to}
      >
        {title}
      </Link>
    )}
  </>
);
