import { useStaticQuery, graphql } from 'gatsby';

export const useSiteMetadata = (): GatsbyTypes.SiteSiteMetadata => {
  const { site } = useStaticQuery<GatsbyTypes.SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          title
          description
          siteUrl
          author
          keywords
          image
          navigation {
            name
            path
          }
          social {
            facebook
            instagram
          }
          contact {
            email
            phone
          }
          bookingLink
        }
      }
    }
  `);

  if (!site?.siteMetadata) {
    throw Error('GraphQL query returned empty results');
  }

  return site.siteMetadata;
};
