import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';
import { Container } from '../container';
import { Link } from 'gatsby';
import { ButtonLink } from '../button-link';

export const Header: React.FC = () => {
  const { navigation, contact, social, bookingLink } = useSiteMetadata();

  return (
    <header>
      <div className="container mx-auto">
        <div className="max-w-3xl mx-auto sm:relative">
          <StaticImage
            className="mx-auto"
            src="../../../static/images/logo.png"
            layout="fixed"
            width={200}
            alt="Always Alive Bodywork Logo"
          />
          <div className="mb-4 text-center sm:absolute sm:right-0 sm:bottom-4 sm:mb-0">
            {bookingLink && (
              <ButtonLink
                className="inline-block"
                title="Schedule a Session"
                to={bookingLink}
                external
              />
            )}
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <Container>
          <nav className="flex items-center justify-between w-full text-sm sm:w-auto">
            <ul className="flex justify-between px-2 sm:px-0">
              {navigation &&
                navigation.map(
                  (nav) =>
                    nav &&
                    nav.path && (
                      <li key={nav.name} className="px-2 py-4 text-sm sm:px-4">
                        <Link key={nav.name} to={nav.path} title={nav.name}>
                          {nav.name}
                        </Link>
                      </li>
                    )
                )}
            </ul>
            <ul className="flex justify-between pr-2 sm:pl-6">
              {contact?.phone && (
                <li className="px-2">
                  <a
                    className="underline"
                    href={`tel:${contact.phone}`}
                    target="_blank"
                    rel="noreferrer noopener"
                    title="Phone number"
                  >
                    {contact.phone}
                  </a>
                </li>
              )}
              <li className="hidden sm:block">
                <ul className="flex justify-between pr-2 sm:pl-6">
                  {social?.facebook && (
                    <li className="px-2">
                      <a
                        href={social.facebook}
                        target="_blank"
                        rel="noreferrer noopener"
                        title="Facebook"
                      >
                        <FontAwesomeIcon icon={faFacebookF} />
                      </a>
                    </li>
                  )}
                  {social?.instagram && (
                    <li className="px-2">
                      <a
                        href={social.instagram}
                        target="_blank"
                        rel="noreferrer noopener"
                        title="Instagram"
                      >
                        <FontAwesomeIcon icon={faInstagramSquare} />
                      </a>
                    </li>
                  )}
                </ul>
              </li>
            </ul>
          </nav>
        </Container>
      </div>
    </header>
  );
};
