import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons';
import { StaticImage } from 'gatsby-plugin-image';

import { useSiteMetadata } from '../../hooks/useSiteMetadata';

export const Footer: React.FC = () => {
  const { title, contact, social } = useSiteMetadata();

  return (
    <footer className="py-10 bg-gray-100">
      <div className="mx-auto text-sm sm:grid sm:max-w-3xl sm:grid-cols-3">
        <div className="text-center">
          <StaticImage
            className="mx-auto"
            layout="fixed"
            height={140}
            src="../../../static/images/logo.png"
            alt={`${title} logo`}
          />
        </div>
        <div className="flex flex-col justify-center h-full mb-4 text-center sm:mb-0">
          <div>
            {contact && (
              <a
                className="underline"
                href={`tel:${contact.phone}`}
                target="_blank"
                rel="noreferrer noopener"
                title="Phone number"
              >
                {contact.phone}
              </a>
            )}
          </div>
          <div>
            {contact && (
              <a className="underline" href={`mailto:${contact?.email}`}>
                {contact?.email}
              </a>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center h-full text-center">
          <div className="flex items-center justify-center">
            {social?.facebook && (
              <a
                className="px-2"
                href={social.facebook}
                target="_blank"
                rel="noreferrer noopener"
                title="Facebook"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            )}
            {social?.instagram && (
              <a
                className="px-2"
                href={social.instagram}
                target="_blank"
                rel="noreferrer noopener"
                title="Instagram"
              >
                <FontAwesomeIcon icon={faInstagramSquare} />
              </a>
            )}
          </div>
        </div>
      </div>
      <div className="mb-4 text-sm text-center">
        ©{new Date().getFullYear()} by Always Alive Bodywork
      </div>
    </footer>
  );
};
