import React from 'react';

import { Footer } from '../footer';
import { Header } from '../header';

export const Layout: React.FC = ({ children }) => (
  <main className="font-light font-openSans">
    <Header />
    {children}
    <Footer />
  </main>
);
